<template>
  <ion-page>
    <ion-header class="headerTop ion-no-border">
      <headertoolbar></headertoolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <div class="original_main_container">
        <ion-header collapse="condense"></ion-header>
        <ion-icon class="l_close_a" button @click="menuNavigation('/tabs/tabReproductor')"
          src="assets/ICONOS/close.svg"></ion-icon>

        <p class="tabTitle">Administrador Dashboard</p>

        <select class="channel_select_e" v-model="selectedChannel" @change="handleChannelChange" aria-label="Canal"
          interface="popover" placeholder="Selecciona el canal">
          <option v-for="item in channel_name_list" :key="item.id" :value="item.id">
            {{ item.name }}
          </option>
        </select>


        <div class="dashRow flex">
          <div class="dash_l_div" size="12" size-sm="12" size-md="5" size-lg="4">
            <ion-card class="dashCardhead">
              <ion-card-header>
                <ion-card-title class="head_name">{{ dashboard.radio.name }}</ion-card-title>
                <ion-card-subtitle class="head_link">{{ dashboard.radio.link }}</ion-card-subtitle>
              </ion-card-header>

              <ion-card-content> <img :style="'background-color: #' + dashboard.radio.color + ';'" class='channelIMG'
                  :src="'https://panel.vybroo.net/media/' + dashboard.radio.mobileimage" alt="Logo"> </ion-card-content>
            </ion-card>
          </div>
          <div class="dash_r_div" size="12" size-sm="12" size-md="7" size-lg="8">
            <ion-grid>
              <ion-row class=" ">
                <ion-col size="12" size-sm="6" size-md="6" size-lg="6">
                  <ion-card class="dashCard dashCard_catego">
                    <ion-card-header>
                      <ion-card-title>Categorias</ion-card-title>
                      <ion-card-subtitle>Musicales</ion-card-subtitle>
                    </ion-card-header>

                    <ion-card-content>
                      <ion-text>
                        <h1>{{ dashboard.radio.categoriesno }}</h1>
                      </ion-text>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
                <ion-col size="12" size-sm="6" size-md="6" size-lg="6">
                  <ion-card class="dashCard dashCard_blacklist">
                    <ion-card-header>
                      <ion-card-title>BlackList</ion-card-title>
                      <ion-card-subtitle>Canciones en la lista negra</ion-card-subtitle>
                    </ion-card-header>

                    <ion-card-content>
                      <ion-text>
                        <h1>{{ dashboard.radio.blacklistno }}</h1>
                      </ion-text>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
                <ion-col size="12" size-sm="6" size-md="6" size-lg="6">
                  <ion-card class="dashCard dashCard_tags">
                    <ion-card-header>
                      <ion-card-title>Tags BlackList</ion-card-title>
                      <ion-card-subtitle>Tags en la lista negra</ion-card-subtitle>
                    </ion-card-header>

                    <ion-card-content>
                      <ion-text>
                        <h1>{{ dashboard.radio.tags_blacklistno }}</h1>
                      </ion-text>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
                <ion-col size="12" size-sm="6" size-md="6" size-lg="6">
                  <ion-card class="dashCard dashCard_spots">
                    <ion-card-header>
                      <ion-card-title>Spots</ion-card-title>
                      <ion-card-subtitle>Producidos / Disponibles</ion-card-subtitle>
                    </ion-card-header>

                    <ion-card-content>
                      <ion-text>
                        <h1>{{ dashboard.spots.produced }}/{{ dashboard.spots.obligation }}</h1>
                      </ion-text>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>


        <ion-grid>
            <ion-row>
              <ion-col size="12" size-sm="12" size-md="6" size-lg="4">
                                <div class="card card-1 card_bottom" @click="menuNavigation('/admin/conexiones')">
                                  <h3>Monitor de Sucursales</h3>
                                  <p>Podras observar en tiempo real las sucursales conectadas en tu canal de radio empresarial.</p>
                                </div>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="6" size-lg="4">
                                <div class="card card-2 card_bottom"  @click="menuNavigation('/admin/cabinaAdmin')">
                                  <h3>Intervencion auditiva</h3>
                                  <p>Graba un audio y selecciona donde quieres que salga al aire, al terminar el audio actual.</p>
                                </div>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="6" size-lg="4">
                                <div class="card card-3 card_bottom" @click="menuNavigation('/admin/logs')">
                                  <h3>Logs de Reproducción</h3>
                                  <p>Podras ver la lista completa de todo el contenido que se escucho en el canal principal.</p>
                                </div>
              </ion-col> 
              <ion-col size="12" size-sm="12" size-md="6" size-lg="6">
                                <div class="card card-4 card_bottom" @click="menuNavigation('/admin/spots')">
                                  <h3>Evidencias de Spots</h3>
                                  <p>Podras ver la lista completa de todo el contenido que se escucho en el canal principal.</p>
                                </div>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="6" size-lg="6">
                                <div class="card card-4 card_bottom" @click="menuNavigation('/admin/musica')">
                                  <h3>Evidencia Musical</h3>
                                  <p>Podras ver la lista completa de todo el contenido que se escucho en el canal principal.</p>
                                </div>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="6" size-lg="6">
                                <div class="card card-5 card_bottom" @click="menuNavigation('/admin/sucursales')">
                                  <h3>Sucursales del Canal</h3>
                                  <p>Mira y administra toda la lista de sucursales de tu radio digital de manera rapida.</p>
                                </div>
              </ion-col>
              <ion-col size="12" size-sm="12" size-md="12" size-lg="6">
                                <div class="card card-6 card_bottom" @click="menuNavigation('/admin/configuraciones')">
                                  <h3>Configuracion del Canal</h3>
                                  <p>Configura lo que pueden ver tus colaboradores al entrar a su sesion de radio digital.</p>
                                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <br><br><br>
      </div>
    </ion-content>
    <!-- <ion-button @click="signOut" class="logoutButtonCabina"><ion-icon src="assets/ICONOS/cabina/log-out-outline.svg"></ion-icon>Cerrar sesión</ion-button> -->
  </ion-page>
</template>


<script>
/* eslint-disable */
import { alertController, IonPage, IonHeader, IonToolbar, IonContent, IonRefresher, IonRefresherContent } from '@ionic/vue';
import axios from 'axios';
import { defineComponent } from 'vue';
import { Storage } from '@capacitor/storage';
import headertoolbar from '../header_toolbar.vue';
import { Capacitor } from '@capacitor/core';
import 'url-polyfill';
import 'blob-polyfill';

export default ({
  name: 'Reproductor',
  components: { IonHeader, IonToolbar, IonContent, IonPage, headertoolbar, IonRefresher, IonRefresherContent },
  data() {
    return {
      urlApi: 'https://panel.vybroo.net/',
      clientData: null,
      clipContainer: null,
      token: null,
      clientData: {},
      channel_name_list: [],
      channel_list: [],
      selectedChannel: null,
      dashboard:
      {
        client: {
          email: "",
          id: 0,
          name: "",
          obligation: 0,
          retainer: 0
        },
        radio: {
          blacklist: "",
          branches: 0,
          categories: "",
          image: "2f07f7cfc8d4348937078a566c7d943e.png",
          link: "",
          stream: "",
          mobileimage: "6b8b53effc9683373db10671d4bf0687.png",
          name: "",
          tags_blacklist: "",
          video_enabled: false
        },
        spots: {
          id: 0,
          level: 0,
          month: "",
          name: "",
          obligation: 0,
          percentage: "",
          produced: null,
          year: ""
        },
      }
      ,
    }
  },
  methods: {
    async menuNavigation(url) {
      if (url == '/admin/cabinaAdmin') {
        var alert = await alertController
          .create({
            header: "Aviso",
            message: "El reproductor se detendrá para acceder a la cabina de intervención auditiva, ¿Desea continuar?",
            buttons: [
              {
                text: 'Si',
                handler: () => {
                  this.emitter.emit("rep-func-pause", "Muteando Reproductor");
                  this.$router.push(url);
                }
              },
              {
                text: 'Cancelar',
                handler: () => {
                  console.log('Cancelar pulsado');
                }
              },
            ]
          });
        return alert.present();

      } else {
        this.$router.push(url);
      }
    },
    handleChannelChange(event) {
      //console.log('Canal seleccionado:', event.target.value);
      this.loadChannelDashboard(event.target.value);
      this.emitter.emit("selectedChannel", this.selectedChannel);
      Storage.set({
        key: "cabina_admin_selectedChannel",
        value: this.selectedChannel,
      });
    },
    async loadChannelDashboard(channel_id) {
      //console.log("========loadChannelDashboard");
      const formData2 = new FormData();
      formData2.append("id", channel_id);
      await axios({
        method: "POST",
        url: "https://panel.vybroo.net/client_dashboard",
        data: formData2,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      })
        .then(async res => {
          //console.log("channel_list000", res.data.data);
          this.setChannelDashboard(res.data.data);
        }).catch(error => {
          console.log("Error en función loadChannelDashboard de Dashboard: " + error.message);
        });
    },
    setChannelDashboard(data) {
      //console.log("setChannelDashboard called", data);

      if (data.radio.blacklist == '' || data.radio.blacklist == null || data.radio.blacklist == undefined) {
        data.radio.blacklistno = 0;
      } else {
        data.radio.blacklistno = data.radio.blacklist.split(',').length;
      }
      if (!data.radio.MusicVariants) {
        data.radio.categoriesno = 0;
      } else {
        data.radio.categoriesno = data.radio.MusicVariants.length;
      }
      if (data.radio.tags_blacklist == '' || data.radio.tags_blacklist == null || data.radio.tags_blacklist == undefined) {
        data.radio.tags_blacklistno = 0;
      } else {
        data.radio.tags_blacklistno = data.radio.tags_blacklist.split(',').length;
      }
      if (data.spots.produced == null || data.spots.produced == '' || data.spots.produced == undefined) {
        data.spots.produced = 0;
      }
      if (data.spots.obligation == null || data.spots.obligation == '' || data.spots.obligation == undefined) {
        data.spots.obligation = 0;
      }

      this.dashboard = data;
      //console.log("dashboard-dashboard", this.dashboard);

      this.emitter.emit('dashboard', this.dashboard);

    },
    async presentAlertConfirm(headerAlert, messageAlert, buttonTextConfirm, buttonTextCancel, functionConfirm, functionCancel) {
      const alert = await alertController
        .create({
          header: headerAlert,
          message: messageAlert,
          buttons: [
            {
              text: buttonTextConfirm,
              handler: () => {
                functionConfirm();
              }
            },
            {
              text: buttonTextCancel,
              handler: () => {
                functionCancel();
              }
            }
          ]
        });
      return alert.present();
    },
    async signOut() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "¿Estás seguro que deseas cerrar la sesión?",
          buttons: [
            {
              text: 'Si',
              handler: () => {
                Storage.remove({ key: 'authToken' });
                Storage.remove({ key: 'cabina_admin_user' });
                Storage.remove({ key: 'cabina_admin_channel_list' });
                Storage.remove({ key: 'cabina_admin_selectedChannel' });
                this.$router.replace("/admin/LoginAdmin");
                this.emitter.emit('cabina_admin_user', '',);
                this.emitter.emit('cabina_admin_channel_list', '',);
              }
            },
            {
              text: 'Cancelar',
              handler: () => {
                console.log('Cancelar pulsado');
              }
            },
          ]
        });
      return alert.present();

    },
    async getStorageClientData(keyName) {
      try {
        const ret = await Storage.get({ key: keyName });
        this.clientData = this.isJson(ret.value);
        // console.log(this.clientData);
      } catch (error) {
        console.log("Error en función getStorageClientData de Dashboard: " + error.message);
      }
    },
    isJson(str) {
      try {
        const parsedStr = JSON.parse(str);
        return parsedStr;
      } catch (e) {
        return str;
      }
    },
    async getStorageAuthToken() {
      try {
        console.log("Esperando 2 segundos para obtener el token...");
        await new Promise(resolve => setTimeout(resolve, 2000));
        const ret = await Storage.get({ key: 'authToken' });
        if (ret.value) {
          const tokenObj = JSON.parse(ret.value);
          this.token = tokenObj.value;
          return this.validateToken(this.token);
        } else {
          console.log("Token no encontrado");
          this.presentAlertTokenInvalid();
          return false;
        }
      } catch (error) {
        console.log("Error en la función getStorageAuthToken de Dashboard: " + error.message);
      }
    },
    async presentAlertTokenInvalid() {
      const alert = await alertController
        .create({
          header: "Aviso",
          message: "Tu sesión ha expirado, por favor inicia sesión nuevamente",
          buttons: [
            {
              text: 'Aceptar',
              handler: () => {
                this.$router.replace("/admin/LoginAdmin");
              }
            },
          ]
        });
      return alert.present();
    },
    async validateToken(token) {
      try {
        //console.log("revisando validez de token: " + token + " ...");
        if (token == null || token == undefined || token == "") {
          this.presentAlertTokenInvalid();
          return false;
        }
        if (token != null && token != undefined && token != "") {
          let url = "api/user_auth";
          if (Capacitor.getPlatform() == 'ios') url = "https://panel.vybroo.net/user_auth";
          let response = await axios.post(url, { token: token })
            .then(r => {
              //console.log("r.data:");
              //console.log(r.data);
              return r;
            }).catch(error => {
              //console.log("Error en función validateToken de Dashboard: " + error.message);
              return error;
            });
          //console.log("response.data:");
          //console.log(response.data);
          //console.log("response.data.success: " + response.data.success);
          //console.log("response.data.error: " + response.data.error);
          if (response.data.error === true) {
            //console.log("Error al validar token:");
            //console.log(response.data);
            this.presentAlertTokenInvalid();
            return false;
          }
          if (response.data.success === true) {
            //console.log("Token válido");
            return true;
          } else {
            //console.log("Token inválido");
            //console.log("2response.data.success: " + response.data.success);
            //console.log("2response.data.error: " + response.data.error);
            this.presentAlertTokenInvalid();
            return false;
          }
        }
      } catch (error) {
        console.log("Error en función validateToken de Dashboard: " + error.message);
        return false;
      }
    },
    async getFullClientData() {
      try {
        const ret = await Storage.get({ key: "clientData" });
        if (ret.value) {
          this.clientData = JSON.parse(ret.value);
          //console.log("Recuperado de storage, key: clientData - " + this.clientData);

          if (this.clientData.sonara) {
            this.emitter.emit("sonara", true);
          } else {
            this.emitter.emit("sonara", false);
          }
        } else {
          //console.log("No se pudo recuperar de storage, key: clientData - " + this.clientData);
        }
      } catch (e) {
        this.presentAlertConfirm("Ha ocurrido un error en la función getFullClientData", e);
      }
    },
    async getChannelList() {
      try {
        const ret = await Storage.get({ key: "cabina_admin_channel_list" });
        if (ret.value) {
          this.channel_list = JSON.parse(ret.value).slice();
          this.emitter.emit("channel_list", this.channel_list);
          this.channel_name_list = [];
          if (this.channel_list){
            this.channel_list.forEach(element => {
              this.channel_name_list.push({ id: element.id, name: element.name });
            });
            this.emitter.emit("channel_name_list", this.channel_name_list);
            //console.log("Recuperado de storage, key: cabina_admin_channel_list - " , this.channel_list);
            //console.log("Recuperado de storage, key: channel_name_list - " , this.channel_name_list);
            if (this.selectedChannel == [] || this.selectedChannel == null || this.selectedChannel == undefined) {
              this.selectedChannel = this.channel_list[0].id;
              this.loadChannelDashboard(this.selectedChannel);
              this.emitter.emit("selectedChannel", this.selectedChannel);
              Storage.set({
                key: "cabina_admin_selectedChannel",
                value: this.selectedChannel,
              });

            }
          }
        } else {
          console.log("No se pudo recuperar de storage, key: cabina_admin_channel_list - ", this.channel_list);
        }
      } catch (error) {
        console.log("Error en función cabina_admin_channel_list de Dashboard: ", error.message);
        return false;
      }
    }

  },
  mounted: function () {
    try {
      if (Capacitor.getPlatform() == 'ios') {
        this.urlApi = "https://panel.vybroo.net/";
      } else {
        this.urlApi = "/api/";
      }

      //get auth token de storage y asignarlo a la variable token
      this.getStorageAuthToken();


      //obtener datos del cliente
      this.getStorageClientData("clientData");
      this.getFullClientData();

      this.emitter.on("cabina_admin_channel_list", (channel_list) => {
        //console.log("Emitido cabina_admin_channel_list: " , channel_list);
        this.channel_list = channel_list;
        this.channel_name_list = [];
        this.channel_list.forEach(element => {
          this.channel_name_list.push({ id: element.id, name: element.name });
        });
        this.emitter.emit("channel_list", this.channel_list);
        this.emitter.emit("channel_name_list", this.channel_name_list);
        this.emitter.emit("selectedChannel", this.selectedChannel);
        Storage.set({
          key: "cabina_admin_selectedChannel",
          value: this.selectedChannel,
        });
      });
      if (this.channel_list.length == 0) {
        this.getChannelList();
      }
      this.emitter.on("selectedChannel", (channel) => {
        //console.log("Emitido cabina_admin_channel_list: " , channel);
        this.loadChannelDashboard(channel);
        this.selectedChannel = channel;
      });


    } catch (error) {
      console.log("Error en función mounted de Dashboard: " + error.message);
    }


  },

})
</script>
<style lang="css">
body {
  font-family: 'Nunito', sans-serif;
  padding: 50px;
}

.connection-grid {
  margin-top: 10px;
}

.card {
  border-radius: 4px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .3s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.card h3 {
  font-weight: 600;
}

.card img {
  position: absolute;
  top: 20px;
  right: 15px;
  max-height: 120px;
}

.card_bottom {
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
  border: solid 1px #272727;
  padding: 12px 80px 12px 24px;
}

.card-1 {
  background-image: url('/img-admin/conexiones.png');
}

.card-2 {
  background-image: url('/img-admin/radio.png');
}

.card-3{
   background-image: url('/img-admin/logs.png');
}

.card-4{
   background-image: url('/img-admin/logs2.png');
}

.card-5{
   background-image: url('/img-admin/sucursales.png');
}

.card-6{
   background-image: url('/img-admin/configuraciones.png');
}

.dashCard.dashCard_catego,
.dashCard.dashCard_blacklist,
.dashCard.dashCard_tags,
.dashCard.dashCard_spots {
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: auto 55%;
  padding: 0 80px 0 0;
}

.dashCard.dashCard_catego {
  background-image: url('/img-admin/category.svg');
}

.dashCard.dashCard_blacklist {
  background-image: url('/img-admin/blacklist.svg');
}

.dashCard.dashCard_tags {
  background-image: url('/img-admin/tag.svg');
}

.dashCard.dashCard_spots {
  background-image: url('/img-admin/spot.svg');
}

.dashCardhead {
  padding: 14px;
  text-align: center;
  margin-top: 20px;
}

.dashCardhead,
.dashCard {
  background-color: black;
  border-radius: 6px;
  color: #fff;
  border: solid 1px #272727;
}

.dashCard::before {
  content: "";
  background-color: rgba(4, 148, 156, 0.1);
  font-size: 0px;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 20%;
  border-radius: 89px 0 0px 0;
  z-index: 0;
  display: none;
}

.dashCard {}

.dashCardhead .head_name {
  font-size: 1.5em;
  font-weight: 700;
  color: #fff;
}

.dashCardhead .head_link {
  font-size: 1em;
  font-weight: 400;
  color: #d3d3d3;
}

.dashCardhead ion-card-header {
  padding: 6px 0;
}

.channelIMG {
  width: 200px;
  margin: auto;
  display: block;
  max-width: 100%;
  border: solid 1px #272727;
  border-radius: 5px;
}

.dashCard h1 {
  font-size: 2.5em;
  font-weight: 700;
  margin: 0;
}

.dashRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.dash_l_div {
  width: 300px !important;
  max-width: 300px !important;
}

.dash_r_div {
  width: calc(100% - 300px) !important;
  max-width: calc(100% - 300px) !important;
}

.channel_select_e {
  width: calc(100% - 20px);
  margin: 0 10px;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #232323;
  background-color: #000000;
  color: #ffffff;
  font-size: 1em;
  font-weight: 400;
  margin-bottom: 10px;
}

@media(max-width: 1420px) {

  .dashCard.dashCard_catego,
  .dashCard.dashCard_blacklist,
  .dashCard.dashCard_tags,
  .dashCard.dashCard_spots {
    background-position: 95% center;
    background-size: auto 45%;
    padding: 0px 65px 0 0;
  }
}

@media(max-width: 960px) {
  .dashCardhead {
    padding: 14px;
  }

  .dash_l_div {
    width: 250px !important;
    max-width: 250px !important;
  }

  .dash_r_div {
    width: calc(100% - 250px) !important;
    max-width: calc(100% - 250px) !important;
  }

  .dashCardhead .head_name {
    font-size: 1.3em;
  }

  .dashCard.dashCard_catego,
  .dashCard.dashCard_blacklist,
  .dashCard.dashCard_tags,
  .dashCard.dashCard_spots {
    background-position: 95% 95%;
    background-size: auto 35%;
    padding: 0px 0px 0 0;
  }

  .dashCard h1 {
    font-size: 2.3em;
  }

  .dashCardhead ion-card-header {
    padding: 10px 0 27px 0;
  }
}

@media(max-width: 767px) {
  .dash_l_div {
    width: 100% !important;
    max-width: 100% !important;
  }

  .dash_r_div {
    width: 100% !important;
    max-width: 100% !important;
  }

  .dashCardhead {
    display: flex;
    width: calc(100% - 40px);
    margin: 20px auto 10px auto;
  }

  .dashCardhead ion-card-header {
    order: 2;
    width: calc(100% - 100px) !important;
    text-align: left;
    padding: 20px 10px;
  }

  .dashCardhead ion-card-content {
    order: 1;
    width: 100px;
    padding: 0px;
    margin: 0px;
  }

  .dashCardhead .head_name {
    font-size: 1.5em;
  }

  .dashCardhead .head_link {
    font-size: 1.2em;
  }

  .channelIMG {
    width: 100px;
  }
}

@media(max-width: 400px) {
  .card_bottom h3 {
    font-size: 1.2em;
  }

  .card_bottom p {
    font-size: 0.9em;
  }
}
</style>